import React from "react"

export default props => (
    
    <div
          className="full-width-image-container margin-top-0 is-hero"
          style={{
            backgroundImage: "url("+ props.heroBanner +")"
          }}
        >
          <div className="columns is-10 is-offset-1">  
            <div className="column">
                <h1
                    className="has-text-weight-bold is-size-1 is-size-3-mobile"
                    style={{
                    boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
                    backgroundColor: '#f40',
                    color: 'white',
                    padding: '1rem',
                    }}
                >
                    {props.headerText}
                </h1>
            </div>
            <div className="column content">
                <p
                    style={{
                        color: 'white',
                    }}
                    className="is-title is-size-3 is-size-5-mobile"
                >{props.subHeaderText}
                </p>
            </div>
          </div>
    </div>
)

