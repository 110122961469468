import React from 'react'
import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-3">
              <section className="menu">
                  <ul className="menu-list">
                  <li><a className="navbar-item" href="https://getlocl.com">Getlocal ehf</a></li>
                  <li><a className="navbar-item" href="mailto:info@getlocal.is">info@getlocal.is</a></li>
                  <li><a className="navbar-item" href="tel:003548880020">888 0020</a></li>
                </ul>
                </section>
              </div>
              <div className="column is-3">
                <section className="menu">
                  <ul className="menu-list">                    
                    
                    <li>
                      <Link className="navbar-item" to="/product/">
                        Markaðstorgið
                      </Link>
                    </li>
                   
                    <li>
                      <Link className="navbar-item" to="/pricing/">
                        Kostnaður
                      </Link>
                    </li>

                    <li>
                      <Link className="navbar-item" to="/faq/">
                        Spurt og svarað
                      </Link>
                    </li>

                  </ul>
                </section>
              </div>
              
              <div className="column is-3">
                <section>
                  <ul className="menu-list">
                  <li>
                      <Link className="navbar-item" to="/about/">
                        Um okkur
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact/">
                        Hafa samband
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog/">
                        Fréttir og tilkynningar
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-3 social">
                <a title="facebook" href="https://facebook.com/getlocal">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/getlcl">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com/getlocaliceland">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
            <p className="section container">Allur réttur áskilinn Getlocal ehf. - <a href="mailto:info@getlocal.com">info@getlocl.com</a> - +354 888 0020</p>
          </div>
        </div>
        

      </footer>

    )
  }
}

export default Footer
