import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import { Link } from 'gatsby'
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="Custom Bokun development"
        description="Our engineers are Bokun experts and we specialize in building custom solutions for Bokun"
        pathname="/services/custom-development/"
    />
        
    <Hero 
         headerText="Custom development" 
         subHeaderText="Building custom solutions on top of Bokun"
            /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Bokun integration specialists</h2>
            
            <p>Our development team is experienced in working with the Bokun API and using the Bokun data to build custom solutions. We can solve problems of various sizes and complexities.</p>
            <CTA />
        </div>

      </div>
    </section>

    <section className=" column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content  intro">
    
            <h3>Previous projects include:</h3>

            <ul>
                <li>Mobile apps</li>
                <li>Resource managers/planners</li>
                <li>"Uber like" where is my pick-up</li>
                <li>Search engines</li>
                <li>Data exports/imports</li>
                <li>Notification engines</li>
                <li>Payment processing integrations</li>
                <li>And more</li>
            </ul>
            
            <p className="center" style={{marginTop: 30, marginBottom: 50}}><Link to="/contact/" className="btn">Contact us for more information</Link></p>

        </div>

      </div>
    </section>

    

  </Layout>
)
